const links = [
    {
        path: '/',
        text: 'home'
    },
    {
        path: '/apps',
        text: 'apps'
    },
    {
        path: '/about',
        text: 'about'
    },
    {
        path: '/contact',
        text: 'contact'
    }
];

export default links;