import React from 'react'
import {Link} from 'gatsby'

import * as styles from '../css/footer.module.css'
// import links from '../constants/links'
// import socialIcons from '../constants/social-icons'
// import {FaRProject} from 'react-icons'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            {/* <div className={styles.links}>
                {links.map((item, index) => {
                    return (
                    <Link key={index} to={item.path}>{item.text}</Link>
                    )
                })}
            </div>
            <div className={styles.icons}>
                {socialIcons.map((item, index) => {
                    return (
                        <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.icon}
                        </a>
                    )
                })}
            </div> */}
            <div className={styles.copyright}>
                &copy; {new Date().getFullYear()} Malaud LLC. all rights reserved.
            </div>
            <div>
                <span> <Link to="/privacy" style={{ 
                    fontFamily: "Lato, sans-serif",
                    color: "white", 
                    textAlign: "right",
                    textDecoration:"none",
                    fontSize: "12px"
                }}>
                    Privacy Policy
                </Link></span>
            </div>
        </footer>
    )
}

export default Footer
