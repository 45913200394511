import React from "react";
import styled from "styled-components";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";

const StyledHero = ({ overlay, img: imgData, className, children }) => {
  const image = getImage(imgData);
  const bgImage = convertToBgImage(image);
  return (
    <BackgroundImage
        Tag="Home"
        className={className}
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        overlay={overlay}
    >
      {/* <GatsbyImage image={image} alt={"Home Banner"}/> */}
      {children}
    </BackgroundImage>
  )
}

export default styled(StyledHero)`
  min-height: ${props => (props.overlay ? "calc(70vh - 62px)" : "70vh")};
  background: ${props =>
    props.overlay
      ? "linear-gradient(rgba(63, 208, 212, 0.7), rgba(0, 0, 0, 0.4))"
      : "none"};
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`
